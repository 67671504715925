import React from "react";
import {
  split,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { useRefreshUserSession } from "components/Auth/CognitoHooks";

interface ApolloAuthProps {}

const ApolloAuthWrapper: React.FunctionComponent<ApolloAuthProps> = ({
  children,
}) => {
  const refreshUserSession = useRefreshUserSession();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_HTTP_URL}`,
  });

  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_GRAPHQL_WS_URL}`,
    options: {
      reconnect: true,
      connectionParams: async () => {
        //  refresh token when needed
        try {
          const user = await refreshUserSession();
          return {
            headers: {
              authorization: `Bearer ${user
                ?.getSignInUserSession()
                ?.getIdToken()
                .getJwtToken()}`,
            },
          };
        } catch (error) {
          return {headers: {}};
        }
      },
    },
  });

  const authLink = setContext(async (_, { headers }) => {
    //  refresh token when needed
    try {
      const user = await refreshUserSession();
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user
            ?.getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
        },
      };
    } catch (error) {
      return { headers };
    }
    
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAuthWrapper;
